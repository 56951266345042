import styled from "styled-components"

export const HeroContainer = styled.div`
  font-family: Roboto;
  margin-top: 100px;
  position: relative;
  height: 600px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  .hero-image {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .hero-blog-listing--wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0 auto;
    padding: 0;
    z-index: 100;
    .hero-blog-listing--burger {
      position: absolute;
      display: flex;
      padding: 24px 60px;
      width: 100%;
      left: 50%;
      top: 0;
      color: white;
      max-width: 1300px;
      transform: translateX(-50%);
      & > * {
        margin: 0 10px 0 0;
      }
    }
    .hero-blog-listing--content {
      padding: 70px 0;
    }
  }
  @media (max-width: 768px) {
    height: 530px;
    .hero-blog-listing--wrapper {
      .hero-blog-listing--burger {
        padding: 24px 30px;
      }
      .hero-blog-listing--content {
        @media (max-width: 768px) {
          padding: 90px 0 0;
        }
      }
    }
  }
  .slick-slider {
    .slick-arrow {
      width: 32px;
      height: 32px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      &:before {
        content: none;
      }
      img {
        width: 10px;
      }
      &.slick-prev {
        left: calc(50% - 650px);
        z-index: 10;
        img {
          transform: rotate(180deg);
        }
        @media (max-width: 1310px) {
          left: -5px;
        }
      }
      &.slick-next {
        right: calc(50% - 650px);
        @media (max-width: 1310px) {
          right: -5px;
        }
      }
    }
  }
`

export const Blur = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 99;
  width: 80%;
  height: 100%;
  transform: translateY(-50%);
  backdrop-filter: blur(15px);
  background: #00000066;
  -webkit-mask-image: linear-gradient(to right, #000000 70%, transparent 100%);
  @media (max-width: 576px) {
    width: 100%;
    backdrop-filter: blur(7px);
    -webkit-mask-image: none;
  }
`

export const HeroFeaturedItem = styled.div`
  .hero-blog-listing--featured-item-wrapper {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding: 0 60px;
    transition: filter 250ms ease;
    &:hover {
      filter: brightness(92%);
    }
    @media (max-width: 768px) {
      padding: 0 30px;
    }
  }
  .hero-blog-listing--featured-item-content {
    color: white;
    font-family: Roboto, Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI";
    width: 100%;
    max-width: 600px;
    height: 440px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 768px) {
      height: auto;
    }
    .hero-blog-listing--featured-item-category {
      padding: 5px 10px;
      width: fit-content;
      display: inline-block;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 1px;
      border-radius: 35px;
    }
    .hero-blog-listing--featured-item-title {
      font-size: 32px;
      font-weight: 600;
      margin: 12px 0 0;
    }
    .hero-blog-listing--featured-item-description {
      font-size: 16px;
      line-height: 150%;
      margin: 10px 0 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .hero-blog-listing--featured-item-author {
      margin: 20px 0 0;
      display: flex;
      align-items: center;
      .hero-blog-listing--featured-item-photo {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .hero-blog-listing--featured-item-author-content {
        margin: 0 0 0 10px;
        .hero-blog-listing--featured-item-name {
          font-size: 17px;
          font-weight: 600;
        }
        .hero-blog-listing--featured-item-position {
          font-size: 15px;
          margin: 3px 0 0;
        }
      }
    }
  }
`

export const HomeCrumb = styled.span`
  &:hover {
    color: #dddddd !important;
  }
`
