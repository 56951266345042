import React from "react"
import Slider from "react-slick"
import { Heading, Text } from "@atoms"
import { Link } from "gatsby"
import {
  HeroContainer,
  Blur,
  HeroFeaturedItem,
  HomeCrumb,
} from "./styles/HeroBlogListing.styled"
import OptimizedImage from "../../utils/optimizedImage"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

const HeroBlogListing = ({ posts, heroData }) => {
  const sliderPosts = posts.map(item => item?.node)

  const sliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    draggable: true,
    swipeToSlide: true,
    speed: 1000,
    nextArrow: (
      <div>
        <img
          style={{ transform: "scale(120%)" }}
          src="/icons/chevron-right-white.svg"
          alt="icon"
        />
      </div>
    ),
    prevArrow: (
      <div>
        <img
          style={{ transform: "scale(120%) rotate(180deg)" }}
          src="/icons/chevron-right-white.svg"
          alt="icon"
        />
      </div>
    ),
  }

  return (
    <HeroContainer data-comp={HeroBlogListing.displayName}>
      <OptimizedImage
        image={heroData?.featuredImage?.gatsbyImageData}
        src={heroData?.featuredImage?.file?.url}
        alt={heroData?.featuredImage?.description || heroData?.featuredImage?.title || "Blog Featured Image"}
        loading="eager"
        className="hero-image"
      />
      <div className="hero-blog-listing--wrapper">
        <div className="hero-blog-listing--burger">
          <Link style={{ textDecoration: "none" }} href="/">
            <HomeCrumb
              id="blog-listing--home-crumb"
              style={{
                textDecoration: "none",
                color: "white",
                transition: "color 200ms ease",
              }}
            >
              Home
            </HomeCrumb>
          </Link>
          <img src="/icons/chevron-right-white.svg" alt="icon" />
          <div style={{ fontWeight: 700, cursor: "pointer" }}>Blog</div>
        </div>
        <div className="hero-blog-listing--content">
          <div className="hero-blog-listing--featured-content">
            <Slider {...sliderSettings}>
              {sliderPosts.map((item, index) => (
                <HeroFeaturedItem className="hero-blog-listing--featured-item">
                  <div className="hero-blog-listing--featured-item-wrapper">
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/blog/${item?.slug}`}
                    >
                      <div className="hero-blog-listing--featured-item-content">
                        {item?.category && (
                          <p
                            className="hero-blog-listing--featured-item-category"
                            style={{
                              marginBottom: "12px",
                              backgroundColor:
                                item?.category[0]?.backgroundColor || "#ddd",
                              color: item?.category[0]?.fontColor || "#1B1B1B",
                            }}
                          >
                            {item?.category[0].title}
                          </p>
                        )}
                        {item?.title && (
                          <Heading
                            headingSize="H2"
                            background="dark"
                            className="hero-blog-listing--featured-item-title"
                          >
                            {item?.title}
                          </Heading>
                        )}
                        {item?.description && (
                          <Text
                            textVariant="light"
                            className="hero-blog-listing--featured-item-description"
                          >
                            {item?.description?.description}
                          </Text>
                        )}
                        {item?.author && (
                          <div className="hero-blog-listing--featured-item-author">
                            <div className="hero-blog-listing--featured-item-photo">
                              <OptimizedImage
                                src={item.author[0].headshot?.file.url}
                                image={
                                  item?.author[0]?.headshot?.gatsbyImageData
                                }
                                alt={item?.author[0]?.fullName || "author"}
                              />
                            </div>
                            <div className="hero-blog-listing--featured-item-author-content">
                              <p className="hero-blog-listing--featured-item-name">
                                {item?.author[0]?.fullName}
                              </p>
                              <p className="hero-blog-listing--featured-item-position">
                                {item?.author[0]?.role}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </Link>
                  </div>
                </HeroFeaturedItem>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <Blur />
    </HeroContainer>
  )
}

export default HeroBlogListing
HeroBlogListing.displayName = "HeroBlogListing"
